import React from "react";
import "./NoContent.css";

export default function NoContent() {
  return (
    <div className="bg-noContend vh-100">
      {/* <p className="text-light ">No tienes permiso para ver este contenido.</p> */}
    </div>
  );
}
