import React from "react";
import "./HomeP1.css";
import jugadores from "../../assets/imgs/personajes.png";
import producto from "../../assets/imgs/banner.png";

import Legales from "./Legales";
import { Link } from "react-router-dom";

export default function HomeP1() {
  return (
    <div className="container-fluid vh-100 d-flex align-items-center flex-column">
      {/* Primera sección */}
      <Legales />

      <div className="row ">
        {/* Para dispositivos móviles y tabletas */}
        <div className="col-12 col-md-6 d-flex flex-column justify-content-end justify-md-center align-items-end align-items-md-center">
          <img
            className="tamano-img-11 imagen-shadows img-fluid"
            src={jugadores}
            alt="jugadores"
          />
        </div>

        {/* Para dispositivos móviles y tabletas */}
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center justify-md-center align-items-start align-items-md-start ">
          <div className="container-text1">
            <span className="text-1 fs-1">¡GANÁ UN VIAJE PARA</span>
          </div>

          <div className="container-text2">
            <span className="text-2 fs-1">
              <span className="text-3 fs-1">IR A VER</span>
              <span className="fs-1"> EL CLÁSICO</span>
              <span className="text-3 fs-1"> A ESPAÑA</span>

              <span className="text-3 fs-1">!</span>
            </span>
          </div>

          <div className="container-text5">
            <span className="text-7">Y muchos premios más...</span>
          </div>

          <div>
            <span className="text-4">
              <span className="d-flex gap-2 text-height">
                <span style={{ color: "#f9ba1d" }}>1. </span>
                Participá comprando Aguardiente o Ron Venado marcado con la
                promoción.
              </span>
              <br />
              <span className="d-flex gap-2 text-height">
                <span style={{ color: "#f9ba1d" }}>2. </span>
                Destapá y quitale el sello al tapón o raspá el sticker
              </span>
              <br />
              <span className="d-flex gap-2 text-height">
                <span style={{ color: "#f9ba1d" }}>3. </span>
                <span>Registrá el código</span>
                <Link to={"/canjearCodigos"} className="paddingSpan">
                  AQUI
                </Link>
              </span>
            </span>
          </div>

          <div className="footerText">
            <span>
              Sorteos semanales a través de Tigo Sports todos los jueves a las
              6:45pm
            </span>
            <br />
            <span>
              Seguínos en
              <a
                href="https://www.facebook.com/EsVenado"
                target="_blank"
                rel="noopener noreferrer"
                className="ms-1 text-white"
                aria-label="Facebook"
              >
                <i className="bi bi-facebook"></i>
                <span className="ms-1">Es_Venado</span>
              </a>
              <a
                href="https://www.instagram.com/esvenado/"
                target="_blank"
                rel="noopener noreferrer"
                className="ms-1 text-white"
                aria-label="Instagram"
              >
                <i className="bi bi-instagram"></i>
                <span className="ms-1">@EsVenado</span>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
