import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Screens/Home";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Error404 from "./Components/Error404/Error404";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "bootstrap-icons/font/bootstrap-icons.css";

import UserRegistration from "./Components/HomeComponents/UserRegistration";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions";
import AgeVerification from "./Components/AgeVerification/AgeVerification";
import useStore from "./stores/useStore";
import NoContent from "./Components/HomeComponents/NoContent";
import MyCodes from "./Components/MyCodes/MyCodes";
import NavBar from "./Components/Nav/Navbar";
import "material-icons/iconfont/material-icons.css";
import Sorteo from "./Screens/Sorteo/Sorteo";
import DondeVer from "./Components/DondeVer/DondeVer";

function App() {
  const { isAdult, acceptedTerms } = useStore();

  return (
    <>
      <BrowserRouter>
        {!isAdult || !acceptedTerms ? (
          <>
            <AgeVerification />
            <NoContent />
          </>
        ) : (
          <div id="container-main" className="bg-image-1">
            <Routes>
              <Route element={<NavBar />}>
                <Route path="/" element={<Home />} />
                <Route path="/Premios" element={<Sorteo />} />
                <Route path="/MisCodigos" element={<MyCodes />} />

                <Route path="/verlossorteos" element={<DondeVer />} />

                <Route path="/canjearCodigos" element={<UserRegistration />} />

                <Route
                  path="/terminosycondiciones"
                  element={<TermsAndConditions />}
                />
                <Route path="*" element={<Error404 />} />
              </Route>
            </Routes>
          </div>
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
