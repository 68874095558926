import React, { useEffect, useState } from "react";
import "./UserRegistration.css"; // Asegúrate de importar el archivo CSS
import Swal from "sweetalert2";
import axios from "axios";

import { URL_SERVER } from "../../api/ApiServer";

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

export default function UserRegistration() {
  console.log(encryptionKey);
  const [formData, setFormData] = useState({
    codigo: "",
    nombre: "",
    apellidos: "",
    pais: "Guatemala",
    fechaNacimiento: "",
    telefono: "",
    telefono_pais: "+502",
    correoElectronico: "",
    documentoIdentificacion: "",
  });

  useEffect(() => {
    // Mostrar el mensaje de agradecimiento al cargar el componente usando SweetAlert2
    Swal.fire({
      icon: "info",
      title: "Gracias por participar",
      text: "Sigue atento a nuestras redes sociales para participar en más promociones.",
      allowOutsideClick: false, // Desactiva clics fuera de la alerta
      confirmButtonText: "Entendido", // Personaliza el botón
      showCancelButton: false, // Desactiva el botón de cancelar
    });
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [banderaPais, setBanderaPais] = useState("fi-gt");
  const [documentoSiglas, setDocumentoSiglas] = useState("DPI");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      telefono: value,
    });
  };

  const handleCountryChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      pais: value,
    });

    switch (value) {
      case "Guatemala":
        setDocumentoSiglas("DPI");
        break;
      case "El Salvador":
        setDocumentoSiglas("DUI");
        break;
      case "Honduras":
        setDocumentoSiglas("DNI");
        break;
      default:
        setDocumentoSiglas("DPI");
    }
  };

  const handleCountryChangeCode = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      telefono_pais: value,
    });

    switch (value) {
      case "+502":
        setBanderaPais("fi-gt");
        break;
      case "+503":
        setBanderaPais("fi-sv");
        break;
      case "+504":
        setBanderaPais("fi-hn");
        break;
      default:
        setBanderaPais("fi-gt");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Recortar espacios al inicio y al final de todos los campos
    const trimmedData = {
      codigo: formData.codigo.trim(),
      nombre: formData.nombre.trim(),
      apellidos: formData.apellidos.trim(),
      pais: formData.pais.trim(),
      fechaNacimiento: formData.fechaNacimiento.trim(),
      telefono: formData.telefono.trim(),
      telefono_pais: formData.telefono_pais.trim(),
      correoElectronico: formData.correoElectronico.trim(),
      documentoIdentificacion: formData.documentoIdentificacion.trim(),
    };

    // Actualizar el estado con los valores recortados
    setFormData(trimmedData);

    let idLength;
    switch (trimmedData.pais) {
      case "Guatemala":
        idLength = 13;
        break;
      case "El Salvador":
        idLength = 13; // Cambiar a 9 si se usa DUI
        break;
      case "Honduras":
        idLength = 14;
        break;
      default:
        idLength = 13;
    }

    if (trimmedData.documentoIdentificacion.length !== idLength) {
      return Swal.fire({
        icon: "error",
        title: "Error",
        text: `El documento de identificación debe tener una longitud de ${idLength} caracteres para ${trimmedData.pais}`,
      });
    }

    setIsSubmitting(true);

    Swal.fire({
      title: "Registrando...",
      text: "Por favor, espera un momento.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // try {
    //   const response = await axios.put(
    //     `${URL_SERVER}/redeemCode/${trimmedData.codigo}`,
    //     {
    //       ...trimmedData,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );

    //   console.log(response);

    //   Swal.fire({
    //     icon: "success",
    //     title: "Gracias por participar",
    //     text: "Sigue atento a nuestras redes sociales para participar en más promociones.",
    //     confirmButtonColor: "#0176ab",
    //     customClass: {
    //       title: "custom-title-class",
    //       text: "custom-text-class",
    //       popup: "bg-card-sweet",
    //     },
    //   }).then(() => {
    //     // Deshabilitar el botón de registro después de que se cierre el modal
    //     setIsSubmitting(true);
    //   });
    // } catch (error) {
    //   console.error(error);

    //   const errorMessage = error.response?.data?.message || error.message;

    //   Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     text: errorMessage,
    //     confirmButtonColor: "#0176ab",
    //     customClass: {
    //       title: "custom-title-class",
    //       text: "custom-text-class",
    //       popup: "bg-card-sweet",
    //     },
    //   });
    // } finally {
    //   setIsSubmitting(false);
    // }
  };

  useEffect(() => {
    handleCountryChangeCode({ target: { value: formData.telefono_pais } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="container-fluid pt-5 pb-5 ">
        <div className="row h-100">
          <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
            <div className="form-container">
              <h2 className="text-center">Registro de Codigos</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="codigo"
                    name="codigo"
                    placeholder="Codigo (8 caracteres)"
                    value={formData.codigo}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="codigo">Código</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="nombre"
                    name="nombre"
                    placeholder="Nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="nombre">Nombre</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="apellidos"
                    name="apellidos"
                    placeholder="Apellidos"
                    value={formData.apellidos}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="apellidos">Apellidos</label>
                </div>
                <div className="form-floating mb-3">
                  <select
                    className="form-control"
                    id="pais"
                    name="pais"
                    value={formData.pais}
                    onChange={handleCountryChange}
                    required
                  >
                    <option value="Guatemala">Guatemala</option>
                    {/* <option value="El Salvador" disabled>El Salvador</option> */}
                    {/* <option value="Honduras" disabled>Honduras</option> */}
                  </select>
                  <label htmlFor="pais">País</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="date"
                    className="form-control"
                    id="fechaNacimiento"
                    name="fechaNacimiento"
                    placeholder="Fecha de Nacimiento"
                    value={formData.fechaNacimiento}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="fechaNacimiento">Fecha de Nacimiento</label>
                </div>
                <div className="form-floating mb-3">
                  <div className="input-group">
                    <span
                      className="input-group-text bg-white"
                      id="basic-addon1"
                    >
                      <span
                        id="banderaPais"
                        className={`fi ${banderaPais}`}
                      ></span>

                      <select
                        className="form-control ms-1"
                        id="telefono_pais"
                        name="telefono_pais"
                        value={formData.telefono_pais}
                        onChange={handleCountryChangeCode}
                        required
                      >
                        <option value="+502">+502</option>
                        {/* <option value="+503" disabled>+503</option> */}
                        {/* <option value="+504" disabled>+504</option> */}
                      </select>
                    </span>
                    <div className="form-floating">
                      <input
                        type="tel"
                        className="form-control"
                        id="telefono"
                        name="telefono"
                        placeholder="Teléfono"
                        value={formData.telefono}
                        onChange={handlePhoneChange}
                        required
                      />
                      <label htmlFor="telefono">Teléfono</label>
                    </div>
                  </div>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="correoElectronico"
                    name="correoElectronico"
                    placeholder="Correo Electrónico"
                    value={formData.correoElectronico}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="correoElectronico">Correo Electrónico</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="documentoIdentificacion"
                    name="documentoIdentificacion"
                    placeholder="Documento de Identificación"
                    value={formData.documentoIdentificacion}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="documentoIdentificacion">
                    Documento de Identificación ({documentoSiglas})
                  </label>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn-sign-up "
                    disabled
                    // disabled={isSubmitting}
                  >
                    {isSubmitting ? "Registrando..." : "Registrar"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
