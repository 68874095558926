// src/stores/useStore.js
import create from "zustand";

const useStore = create((set) => ({
  isAdult: localStorage.getItem("isAdult") === "true",
  acceptedTerms: localStorage.getItem("acceptedTerms") === "true",
  setIsAdult: (value) => set({ isAdult: value }),
  setAcceptedTerms: (value) => set({ acceptedTerms: value }),
}));

export default useStore;
