import React, { useEffect, useRef, useState } from "react";
import "./TermsAndConditions.css";
import useStore from "./../../stores/useStore";

export default function TermsAndConditions() {
  const { acceptedTerms, setAcceptedTerms } = useStore();
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const termsRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const termsElement = termsRef.current;
      if (!termsElement) return;

      const isAtBottom =
        Math.abs(
          termsElement.scrollHeight -
            termsElement.scrollTop -
            termsElement.clientHeight
        ) < 1;
      setIsScrolledToEnd(isAtBottom);
    };

    const termsElement = termsRef.current;
    termsElement.addEventListener("scroll", handleScroll);

    return () => {
      termsElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCheckboxChange = () => {
    const newValue = !acceptedTerms;
    setAcceptedTerms(newValue);
    localStorage.setItem("acceptedTerms", newValue);
  };

  return (
    <div className="container-fluid vh-100 text-light">
      <h2 className="text-center text-terms">Términos y Condiciones</h2>
      <div className="terms-content text-box-terms" ref={termsRef}>
        {/* Aquí debes añadir la información específica */}
        <p>
          Para ser ganador de cualquiera de los premios, debes cumplir con los
          siguientes requisitos:
        </p>
        <p>-Ser mayor de edad y contar con DPI vigente.</p>
        <p>
          -Para canjear cualquiera de los premios, debes contar el sticker del
          código ganador o con la tapa con el código completo y legible.
        </p>
        <p>-Los participantes tienen que residir en el país de Guatemala.</p>
        <p>
          -Los colaboradores de Licores de Guatemala y/o cualquier sociedad que
          pertenezca a la misma, no podrán ser ganadores de ningún premio.
        </p>
        <p>-Los premios son intransferibles.</p>
        <p>
          -Para poder ser acreedor del premio mayor del viaje a ver el clásico a
          España, debes contar con pasaporte vigente.
        </p>
        <p>
          -Al aceptar los términos y condiciones, automáticamente estás dando tu
          consentimiento para que Licores de Guatemala guarde tus datos
          personales.
        </p>

        <p>
          -La vigencia de la promoción es del 15 de agosto al 5 de octubre a las
          17:00 horas.
        </p>
        <p>
          -Si participaste, debes estar atento a la llamada de Venado La Liga
          los días jueves después de las 2pm para saber si eres uno de los
          ganadores de los premios semanales, que son:
        </p>
        <ul>
          <li>PlayStation</li>
          <li>
            Camisa Oficial de La Liga autografiada por una leyenda de La Liga
          </li>
          <li>
            Pelota Oficial de La Liga autografiada por una leyenda de La Liga
          </li>
        </ul>
      </div>
      <div className="text-center mt-3">
        <label>
          <input
            type="checkbox"
            checked={acceptedTerms}
            onChange={handleCheckboxChange}
            disabled={!isScrolledToEnd}
          />{" "}
          He leído y acepto los términos y condiciones
        </label>
      </div>
    </div>
  );
}
