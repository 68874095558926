import { useEffect } from "react";
import Swal from "sweetalert2";
import useStore from "../../stores/useStore";
import "./AgeVerification.css";

const AgeVerification = () => {
  const { setIsAdult, setAcceptedTerms } = useStore();

  useEffect(() => {
    const showAlert =
      !localStorage.getItem("isAdult") ||
      !localStorage.getItem("acceptedTerms");

    if (showAlert) {
      Swal.fire({
        title: "¿Eres mayor de 18 años?",
        text: "Debes ser mayor de 18 años para acceder a este contenido.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        confirmButtonColor: "#0176ab",
        reverseButtons: true,
        customClass: {
          title: "custom-title-class",
          text: "custom-text-class",
          popup: "bg-card-sweet",
          htmlContainer: "terminos-scroll",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Términos y Condiciones",
            html: `
              <div id="terms-content" >
                <p>Para ser ganador de cualquiera de los premios, debes cumplir con los siguientes requisitos:</p>
                <p>-Ser mayor de edad y contar con DPI vigente.</p>
                <p>
                -Para canjear cualquiera de los premios, debes contar el sticker del código ganador o con la tapa con el código completo y legible.
                </p>
                <p>
                -	Los participantes tienen que residir en el país de Guatemala.
                </p>
                <p>-Los colaboradores de Licores de Guatemala y/o cualquier sociedad que pertenezca a la misma, no podrán ser ganadores de ningún premio.</p>
                <p>-Los premios son intransferibles. </p>
                <p>-Para poder ser acreedor del premio mayor del viaje a ver el clásico a España, debes contar con pasaporte vigente</p>
                <p>-Al aceptar los términos y condiciones, automáticamente estás dando tu consentimiento para que Licores de Guatemala guarde tus datos personales.</p>
                <p>-La vigencia de la promoción es del 15 de agosto al 5 de octubre a las 17:00 horas.</p>
<p>Si participaste, debes estar atento a la llamada de Venado La Liga los días jueves después de las 2pm para saber si eres uno de los ganadores de los premios semanales, que son:</p>
<ul>
  <li>PlayStation</li>
  <li>Camisa Oficial de La Liga autografiada por una leyenda de La Liga</li>
  <li>Pelota Oficial de La Liga autografiada por una leyenda de La Liga</li>
</ul>

                </div>
              <div>
                <input type="checkbox" id="termsCheckbox" class="swal2-checkbox"> 
                <label for="termsCheckbox"> Acepto los términos y condiciones</label>
              </div>
            `,
            confirmButtonColor: "#0176ab",
            showCancelButton: true,
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            customClass: {
              title: "custom-title-class",
              text: "custom-text-class",
              popup: "bg-card-sweet",
            },
            willOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              const checkbox = document.getElementById("termsCheckbox");

              confirmButton.disabled = true;

              checkbox.addEventListener("change", (event) => {
                confirmButton.disabled = !event.target.checked;
              });
            },
          }).then((result) => {
            if (
              result.isConfirmed &&
              document.getElementById("termsCheckbox").checked
            ) {
              setAcceptedTerms(true);
              setIsAdult(true);
              localStorage.setItem("isAdult", "true");
              localStorage.setItem("acceptedTerms", "true");
            } else {
              setAcceptedTerms(false);
              setIsAdult(false);
              Swal.fire({
                icon: "error",
                title: "Debes aceptar los términos y condiciones para acceder",
                customClass: {
                  title: "custom-title-class",
                  text: "custom-text-class",
                  popup: "bg-card-sweet",
                },
              });
            }
          });
        } else {
          setIsAdult(false);
          Swal.fire({
            icon: "error",
            title: "Acceso denegado",
            confirmButtonColor: "#0176ab",
            text: "Debes ser mayor de 18 años para acceder a este contenido.",
            customClass: {
              title: "custom-title-class",
              text: "custom-text-class",
              popup: "bg-card-sweet",
            },
          });
        }
      });
    }
  }, [setIsAdult, setAcceptedTerms]);

  return null;
};

export default AgeVerification;
