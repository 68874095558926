import React from "react";

export default function Error404() {
  return (
    <div>
      <p className="error-message">
        La página que estás buscando no se encontró.
      </p>
    </div>
  );
}
