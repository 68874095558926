import React from "react";

import MainHome from "../Components/Mainhome/MainHome";

export default function Home() {
  return (
    <div>
      <MainHome />
    </div>
  );
}
