import React, { useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import "./MyCodes.css";
import { URL_SERVER } from "../../api/ApiServer";

export default function MyCodes() {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Nuevo estado para manejar la carga

  const handleSearch = async () => {
    setLoading(true); // Activar el estado de carga al iniciar la búsqueda
    try {
      const response = await axios.post(
        `${URL_SERVER}/buscarPorIdentificacion`,
        {
          documentoIdentificacion: searchTerm,
        }
      );
      setData(response.data);
      setError(null);
    } catch (err) {
      console.error(err);
      setError("Error al buscar los códigos. Por favor, intenta de nuevo.");
    } finally {
      setLoading(false); // Desactivar el estado de carga al terminar la búsqueda
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex flex-column align-items-center justify-content-start pt-2 pb-2">
      <p className="text-center desc-code">Buscar tus códigos REGISTRADOS</p>
      <div className="search-bar mb-4 ">
        <input
          type="text"
          className="form-control"
          placeholder="Ingresa tu numero de DPI"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className="btn btn-primary"
          onClick={handleSearch}
          disabled={loading}
        >
          <i className="bi bi-search"></i>
        </button>
      </div>
      <div className="results" style={{ overflowY: "scroll" }}>
        {loading && (
          <div
            className="fw-bold text-center result-item"
            style={{ color: "#052863" }}
          >
            Cargando...
          </div>
        )}
        {error && (
          <div
            className="fw-bold text-center result-item"
            style={{ color: "red" }}
          >
            {error}
          </div>
        )}
        {!loading &&
          data.length > 0 &&
          data.map((item) => (
            <div key={item.id} className="result-item">
              <div className="result-code">Código: {item.codigo}</div>
              <div className="result-date">
                Fecha: {format(new Date(item.fechaCajeado), "yyyy-MM-dd")}
              </div>
              <div className="result-time">
                Hora: {format(new Date(item.fechaCajeado), "HH:mm:ss")}
              </div>
            </div>
          ))}
        {!loading && !error && data.length === 0 && (
          <div
            className="fw-bold text-center result-item"
            style={{ color: "#052863" }}
          >
            No se encontraron resultados
          </div>
        )}
      </div>
    </div>
  );
}
