import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import HomeP1 from "../HomeComponents/HomeP1";
import Home3 from "../HomeComponents/Home3";
import Home4 from "../HomeComponents/Home4/Home4";
import "./mainHome.css";

const NextArrow = (props) => {
  const { style, onClick } = props;
  return (
    <div
      // className={`${className} custom-arrow`}
      style={{ ...style, borderRadius: "50%", right: 10 }}
      onClick={onClick}
    >
      <span
        style={{ ...style, borderRadius: "50%", right: 10 }}
        className="material-icons arrow-style"
      >
        arrow_forward_ios
      </span>
      {/* <span style={{ ...style, borderRadius: "50%", right: 10 }} className="material-icons arrow-style">arrow_back_ios</span> */}
    </div>
  );
};

const PrevArrow = (props) => {
  const { style, onClick } = props;
  return (
    <div
      // className={`${className} custom-arrow`}
      style={{
        ...style,
        borderRadius: "50%",
        left: 10,
        zIndex: 2,
      }}
      onClick={onClick}
    >
      <span
        style={{ ...style, borderRadius: "50%", left: 10 }}
        className="material-icons arrow-style"
      >
        arrow_back_ios
      </span>
    </div>
  );
};

export default function MainHome() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (current) => setCurrentSlide(current),
  };

  useEffect(() => {
    const containerMain = document.getElementById("container-main");

    if (containerMain) {
      switch (currentSlide) {
        case 0:
          containerMain.className = "bg-image-main-1";
          break;
        case 1:
          containerMain.className = "bg-image-main-2";
          break;
        case 2:
          containerMain.className = "bg-image-main-3";
          break;
        default:
          containerMain.className = "bg-image-main-1";
      }
    }
  }, [currentSlide]);

  return (
    <div>
      <Slider {...settings}>
        <div>
          <HomeP1 />
        </div>
        <div>
          <Home3 />
        </div>
        <div>
          <Home4 />
        </div>
      </Slider>
    </div>
  );
}
