import React from "react";
import { motion } from "framer-motion";
import venadtoFT from "./../../assets/imgs/VENADO_FT_LALIGA.png";
import "./Home3.css"; // Asegúrate de crear este archivo CSS para los estilos adicionales
import jugadores from "./../../assets/imgs/jugadores.png";
import titular from "./../../assets/imgs/titular.png";
import logoBueno from "./../../assets/imgs/logo_bueno.png";
import Legales from "./Legales";

export default function Home3() {
  return (
    <div className="container-fluid vh-100 d-flex align-items-center flex-column ">
      <Legales />
      {/* <motion.div
        className="row d-flex justify-content-center align-items-center mb-3"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img className="img-fluid width-img-1" src={venadtoFT} alt="imagen" />
      </motion.div> */}

      <motion.div
        className="row d-flex justify-content-center align-items-center  position-relative w-100  h-100"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="col-md-6 position-relative ">
          <div className="d-flex justify-content-center align-items-center">
            <img
              className="tamano-img-1 imagen-shadows"
              src={logoBueno}
              alt="logo"
            />
            <img
              className="tamano-img-2 position-absolute "
              src={jugadores}
              alt="jugadores"
            />
          </div>
          <div className="col-md-6 ">
          <div className="d-flex justify-content-center align-items-center">
            <img className="img-fluid" src={titular} alt="logo"/>
          </div>
        </div>
        </div>
        {/* <div className="col-md-6 mt-5">
          <div className="d-flex justify-content-center align-items-center">
            <img className="img-fluid" src={titular} alt="logo" />
          </div>
        </div> */}
      </motion.div>
    </div>
  );
}
