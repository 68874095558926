import React from "react";
import sorteoImg1 from "./../../assets/imgs/soroteoImg.png";
import sorteoImg2 from "./../../assets/imgs/soroteoImg.png";
import sorteoImg3 from "./../../assets/imgs/soroteoImg.png";
import sorteoImg4 from "./../../assets/imgs/soroteoImg.png";
import sorteoImg5 from "./../../assets/imgs/soroteoImg.png";
import sorteoImg6 from "./../../assets/imgs/soroteoImg.png";
import "./Sorteo.css";

const Sorteo = () => {
  return (
    <div className="container my-4">
      <div className="row">
        {[
          sorteoImg1,
          sorteoImg2,
          sorteoImg3,
          sorteoImg4,
          sorteoImg5,
          sorteoImg6,
        ].map((imgSrc, index) => (
          <div className="col-12 col-md-4 mb-4" key={index}>
            <div className="text-center">
              <img
                src={imgSrc}
                alt={`Sorteo ${index + 1}`}
                className="img-fluid"
              />
              <p className="mt-2 text-white" style={{ fontSize: "small" }}>
                *Sorteos semanales a través de nuestras redes. Seguinos como Es
                Venado en Facebook e Instagram.
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sorteo;
