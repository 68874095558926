import React from "react";
import legales1 from "./../../assets/imgs/legales1.png";
import legales2 from "./../../assets/imgs/legales2.png";
import "./Legales.css"; // Asegúrate de crear este archivo CSS para los estilos adicionales

export default function Legales() {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-md-6 col-6 d-flex justify-content-start align-items-center">
          {/* <img
            src={legales1}
            alt="Imagen Izquierda"
            className="smaller-image"
          /> */}
        </div>
        <div className="col-md-6 col-6 d-flex justify-content-end align-items-center">
          <img src={legales2} alt="Imagen Derecha" className="smaller-image" />
        </div>
      </div>
    </div>
  );
}
