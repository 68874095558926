import React from "react";
import { Link, Outlet } from "react-router-dom";
import venadtoFT from "./../../assets/imgs/VENADO_FT_LALIGA.png";
import "./navbar.css";

export default function NavBar() {
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg text-light"
        style={{
          backgroundColor: "transparent",
          fontFamily: "Gobold, sans-serif",
          color: "white",
          textShadow: "2px 2px 4px #2b4775",
        }}
      >
        <div className="container-fluid">
          <Link className="navbar-brand text-light Gobold fixWidthLogo" to="/">
            <img
              className="img-fluid width-img"
              src={venadtoFT}
              alt="imagen"
              width={"250px"}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ backgroundColor: "#fff" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item ">
                <Link className="nav-link text-light" to="/canjearCodigos">
                  Canjaer códigos
                </Link>
              </li>

              <li className="nav-item ">
                <Link className="nav-link text-light" to="/MisCodigos">
                  Mis códigos
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link className="nav-link  text-light" to="/verlossorteos">
                  ¿Dónde ver los sorteos?
                </Link>
              </li> */}

              <li className="nav-item">
                <Link
                  className="nav-link  text-light"
                  to="/terminosycondiciones"
                >
                  Términos y condiciones
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Outlet></Outlet>
    </div>
  );
}
